<script>
    import { onMount, onDestroy, getContext } from 'svelte';
    import { link } from 'svelte-spa-router';

    import Page from 'components/Page';
    import Container from 'components/Container';
    import Lottie from 'components/Lottie';
    import RegisterForm from 'components/RegisterForm';
    import RegisterFormStatus from 'components/RegisterForm/RegisterFormStatus';
    import { LoadingModal, loadingModalOptions } from 'components/LoadingModal';
    import { AlertModal, alertModalOptions } from 'components/AlertModal';
    import { SupportModal, supportModalOptions } from 'components/SupportModal';

    import SHARED_CONFIG from 'configs/shared';
    import REGISTER_CONFIG from 'configs/register';

    import { createCustomer, checkCustomerExists } from 'services/infinity';
    import analyticsService from 'services/analytics';

    import registerStore from 'stores/register';
    import NavBar from '../../components/NavBar/NavBar.svelte';

    const { open } = getContext('simple-modal');

    let element;
    let queryParams;
    let step = REGISTER_CONFIG.STEPS.ESSENTIALS;
    let isMounted = false;
    let isLoading = false;
    let isLoadingNext = false;
    let isLoadingPrevious = false;
    let isApplicationStartedTracked = false;

    onMount(async () => {
        registerStore.reset();
        parseQueryParams();

        // TODO: Debug mode.
        if ('<@ENV@>' != 'PRODUCTION' && '<@ENV@>' != 'STAGING') {
            // registerStore.populate();
        }

        // Set form values based on the query params
        if (queryParams?.country || queryParams?.type) {
            const selectedCountry = REGISTER_CONFIG.OPTIONS.COUNTRY.find(
                (c) => c?.value?.toLowerCase() == queryParams?.country?.toLowerCase(),
            );

            // Select the Country
            if (selectedCountry) {
                $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country = selectedCountry;
            }

            // Select the Loan type
            if (queryParams?.type) {
                const type = parseInt(queryParams.type);

                if (type == SHARED_CONFIG.LOAN_TYPES.BUSINESS) {
                    if (selectedCountry?.isSME) {
                        $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType = type;
                        step = REGISTER_CONFIG.STEPS.LOAN;
                    }
                } else if (type == SHARED_CONFIG.LOAN_TYPES.PERSONAL) {
                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType = type;
                } else if (type == SHARED_CONFIG.LOAN_TYPES.CAR) {
                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType = type;
                }
            }
        }

        // This isn't the best solution, but for now it'll work.
        // Without this the RegisterForm component gets loaded without the right loan type (race condition).
        wait(() => {
            isMounted = true;
        }, 250);
    });

    function parseQueryParams() {
        const hashParts = window.location.hash.split('?');

        if (hashParts.length < 2) {
            queryParams = {};
            return {};
        }

        const searchParams = new URLSearchParams(hashParts[1]);
        const params = {};

        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }

        queryParams = params;
        return params;
    }

    function wait(fn, seconds) {
        return new Promise((resolve) => {
            setTimeout(function () {
                fn();
                resolve();
            }, seconds);
        });
    }

    function openSupportModal() {
        open(SupportModal, {}, supportModalOptions);
    }

    function handleJumpToStep(event) {
        let detail = event.detail;

        if (detail.currentStep.num > detail.targetStep.num) {
            handlePreviousStep({
                detail: {
                    currentStep: detail.currentStep,
                    previousStep: detail.targetStep,
                },
            });
        } else {
            handleNextStep({
                detail: {
                    currentStep: detail.currentStep,
                    nextStep: detail.targetStep,
                },
            });
        }
    }

    async function handleNextStep(event) {
        let detail = event.detail;

        async function goToNextStep() {
            isLoading = true;
            isLoadingNext = true;
            isLoadingPrevious = false;

            await wait(() => {
                element.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 1000);

            await wait(() => {
                isLoading = false;
                step = detail.nextStep;
            }, 250);
        }

        if (detail.currentStep.key == REGISTER_CONFIG.STEPS.FINANCIALS.key && !isApplicationStartedTracked) {
            analyticsService.track(analyticsService.EVENTS.APPLICATION_STARTED, {
                firstName: $registerStore.details.firstName,
                lastName: $registerStore.details.lastName,
                emailAddress: $registerStore.details.emailAddress,
                country: $registerStore.essentials.country.value,
                loanType: $registerStore.essentials.loanType,
            });

            isApplicationStartedTracked = true;
        }

        if (detail.currentStep.key == REGISTER_CONFIG.STEPS.CREDENTIALS.key) {
            open(
                LoadingModal,
                {
                    title: 'Confirming credentials',
                    message: 'This may take a few seconds ...',
                    promise: checkCustomerExists({ username: $registerStore.credentials.username }),
                    onSuccess: (isExistingCustomer) => {
                        console.debug('Is Existing Customer -> ', isExistingCustomer);
                        if (!isExistingCustomer) {
                            goToNextStep();
                        } else {
                            open(
                                AlertModal,
                                {
                                    title: 'That username is already taken.',
                                    message: 'Please input a different username and try again.',
                                },
                                alertModalOptions,
                            );
                        }
                    },
                    onError: (error) => {
                        console.error('Error confirming credentials -> ', error);
                        return;
                    },
                },
                loadingModalOptions,
            );
        } else if (detail.currentStep.key == REGISTER_CONFIG.STEPS.REVIEW.key) {
            open(
                LoadingModal,
                {
                    title: 'Submitting your application',
                    message: 'Please be patient as we submit your application. This may take a few seconds ...',
                    promise: createCustomer($registerStore),
                    onSuccess: () => {
                        step = REGISTER_CONFIG.STEPS.COMPLETE;
                        $registerStore.isComplete = true;
                    },
                    onError: (error) => {
                        console.error('Error creating customer -> ', error);
                        $registerStore.isComplete = false;
                        open(
                            AlertModal,
                            {
                                title: 'Sorry, we were unable to register your account.',
                                message:
                                    'Please ensure all your information is entered correctly and try again. If the issue persists, please email us at info@mytermfinance.com.',
                            },
                            alertModalOptions,
                        );
                    },
                },
                loadingModalOptions,
            );
        } else {
            goToNextStep();
        }
    }

    async function handlePreviousStep(event) {
        let detail = event.detail;

        isLoading = true;
        isLoadingNext = false;
        isLoadingPrevious = true;

        await wait(() => {
            element.scrollIntoView({
                behavior: 'smooth',
            });
        }, 1000);

        await wait(() => {
            isLoading = false;
            step = detail.previousStep;
        }, 250);
    }

    function handleReset() {
        location.reload();
    }
</script>

<NavBar on:login={() => (window.location.href = '/#/login')} />
<div bind:this={element} class="bg-white lg:bg-secondary">
    <Page>
        <div slot="content">
            <div class="register">
                <div class="register__content register__content--left">
                    <Container>
                        <div class="step-desc-content">
                            <!-- <nav class="nav">
                                <div class="nav__logo">
                                    <a href="/login" use:link>
                                        <img src="images/nav_logo@2x.png" height="60" alt="logo" class="h-[60px]" /></a
                                    >
                                    <a href="https://fastcashcaribbean.com/" target="_blank">
                                        <img
                                            src="images/nav_logo_fc@2x.png"
                                            height="60"
                                            class="h-[60px]"
                                            alt="logo"
                                        /></a
                                    >
                                </div>
                                <div class="nav__breadcrumbs">
                                    <RegisterFormStatus {step} on:jump={handleJumpToStep} />
                                </div>
                            </nav> -->
                            <div class="step-desc-content__flex">
                                <div class="step-desc-content__header animate__animated">
                                    {#if step != REGISTER_CONFIG.STEPS.COMPLETE}
                                        <div class="step-desc-content__subtitle">Step {step.num}</div>
                                    {:else}
                                        <div class="step-desc-content__subtitle">Application Complete</div>
                                    {/if}
                                    <div class="step-desc-content__title">{step.labels.title}</div>
                                    <div class="mb-2 text-sm font-normal text-black lg:text-base">
                                        {step.labels.subtitle}
                                    </div>
                                    <div
                                        class="step-desc-content__support"
                                        on:keydown
                                        on:click={() => {
                                            openSupportModal();
                                        }}
                                    >
                                        Need help? Contact us.
                                    </div>
                                </div>
                                {#if isMounted}
                                    <div class="step-desc-content__animation-container">
                                        <div
                                            class="step-desc-content__animation animate__animated"
                                            class:animate__fadeIn={!isLoading}
                                            class:animate__fadeOut={isLoading}
                                        >
                                            <Lottie jsonPath="lottie/step-{step.num}.json" />
                                        </div>
                                    </div>
                                {/if}
                            </div>
                        </div>
                    </Container>
                </div>
                <div
                    class="register__content register__content--right"
                    class:register__content--completed={step == REGISTER_CONFIG.STEPS.COMPLETE}
                >
                    <!-- <nav class="nav-content">
                        <div class="nav-content__group">
                            <button
                                class="nav-content__btn nav-content__btn--secondary"
                                on:click={() => openSupportModal()}>Contact us</button
                            >
                        </div>
                        <div class="nav-content__group">
                            <a href="/login" use:link
                                ><button class="nav-content__btn">Already a Member? Log In</button></a
                            >
                        </div>
                    </nav> -->
                    <div class="form-container">
                        <div
                            class="form-container__animate animate__animated"
                            class:animate__fadeInRight={!isLoading && isLoadingNext}
                            class:animate__fadeOutLeft={isLoading && isLoadingNext}
                            class:animate__fadeInLeft={!isLoading && isLoadingPrevious}
                            class:animate__fadeOutRight={isLoading && isLoadingPrevious}
                        >
                            {#if isMounted}
                                {#if $registerStore.essentials.loanType == SHARED_CONFIG.LOAN_TYPES.BUSINESS || $registerStore.essentials.loanType == SHARED_CONFIG.LOAN_TYPES.CAR}
                                    {#if step != REGISTER_CONFIG.STEPS.ESSENTIALS && step != REGISTER_CONFIG.STEPS.REVIEW}
                                        <!-- Show the logos -->
                                        {#if $registerStore.essentials.country?.value == 'TT'}
                                            {#if $registerStore.essentials.loanType == SHARED_CONFIG.LOAN_TYPES.BUSINESS}
                                                <img
                                                    src="/images/tf_logo_sme.png"
                                                    class="h-[100px]"
                                                    style="height: 100px"
                                                    height="200"
                                                    alt="myfi"
                                                />
                                            {:else}
                                                <img
                                                    src="/images/tf_logo_sme.png"
                                                    class="h-[100px]"
                                                    style="height: 100px"
                                                    height="200"
                                                    alt="myfi"
                                                />
                                            {/if}
                                        {:else if $registerStore.essentials.country?.value == 'BB'}
                                            <img
                                                src="/images/tf_logo_sme.png"
                                                class="h-[100px]"
                                                style="height: 100px"
                                                height="200"
                                                alt="myfi"
                                            />
                                        {:else if $registerStore.essentials.country?.value == 'GD'}
                                            <img
                                                src="/images/tf_logo_myfi.png"
                                                class="h-[100px]"
                                                style="height: 100px"
                                                height="200"
                                                alt="myfi"
                                            />
                                        {/if}
                                    {/if}
                                {/if}

                                <RegisterForm
                                    {step}
                                    on:next={handleNextStep}
                                    on:previous={handlePreviousStep}
                                    on:jump={handleJumpToStep}
                                    on:reset={handleReset}
                                />
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Page>
</div>

<style lang="scss" type="text/scss">
    @use 'src/sass/base' as *;
    @use 'src/sass/tailwind';

    .nav {
        display: flex;
        z-index: 10;
        flex-flow: column nowrap;
        row-gap: em(20);
        column-gap: em(20);
        justify-content: start;
        align-items: start;
        position: relative;
        margin-bottom: em(25);

        &__logo {
            display: flex;
            justify-self: flex-start;
            align-items: center;
            cursor: pointer;
        }

        &__breadcrumbs {
            font-size: em(18);
            color: $color-gray-dark;
        }

        @include for-desktop-up {
            // flex-flow: row nowrap;
            margin-bottom: em(50);
        }
    }

    .register {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        max-width: 1600px;
        margin: 0 auto;

        &__content {
            position: relative;
            width: 100%;
            margin: 0;
            padding: em(25) 0;

            &--left {
                overflow: hidden;
                background: rgb(230, 228, 254);
                background: linear-gradient(83deg, rgba(230, 228, 254, 1) 0%, rgba(203, 197, 255, 1) 100%);
                background: $color-secondary;
            }

            &--right {
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                background: $color-white;
                border-right: 1px solid darken($color-secondary, 2%);
                border-left: 1px solid darken($color-secondary, 2%);
            }
        }

        @include for-desktop-up {
            flex-flow: row nowrap;
            min-height: 100vh;
            justify-content: center;
            align-items: stretch;

            &__content {
                width: auto;
                height: 100%;
                padding: em(25) 0;

                &--right {
                    width: 50%;
                    min-height: 100vh;
                }

                &--left {
                    width: 50%;
                    min-height: 100vh;
                    background: transparent;
                }
            }
        }
    }

    .step-desc-content {
        position: relative;
        padding: 0;
        // max-height: 250px;
        overflow: hidden;
        margin-top: 100px;

        &__flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        &__header {
            margin-bottom: em(20);
            padding: 0 em(10);
        }

        &__subtitle {
            margin-bottom: em(20);
            padding-bottom: em(5);
            font-weight: $fw-bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: em(16);
            color: $color-primary;
            opacity: 0.7;
            border-bottom: 1px solid rgba($color-primary, 0.3);
        }

        &__title {
            margin-bottom: em(5);
            font-family: $ff-headers;
            font-size: em(24);
            color: $color-black;
            font-weight: $fw-bold;
            line-height: 1.3;
            background: linear-gradient(119deg, rgba(81, 35, 173, 1) 0%, rgba(140, 128, 185, 1) 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &__desc {
            width: 100%;
            margin-bottom: em(15);
            font-family: $ff-primary;
            font-weight: $fw-light;
            font-size: em(14);
            color: $color-black;
            line-height: 1.35;
        }

        &__support {
            cursor: pointer;
            color: $color-primary;
            font-weight: $fw-medium;
            font-size: em(14);

            &:hover {
                color: lighten($color-primary, 5%);
            }
        }

        &__animation {
            display: block;
            position: relative;
            width: em(220);
        }

        @include for-tablet-portrait-up {
            max-height: 100%;

            &__desc {
                width: 100%;
                font-size: em(18);
            }

            &__title {
                margin-bottom: em(5);
                font-size: em(36);
                line-height: 1.3;
            }

            &__animation {
                width: em(400);
            }

            &__header {
                width: 50%;
                padding: 0;
            }

            &__desc {
                display: block;
            }
        }

        @include for-desktop-up {
            position: fixed;
            width: 45%;
            max-width: 500px;
            padding: em(15) em(0);

            &__header {
                width: 100%;
                margin-bottom: em(150);
            }

            &__desc {
                width: 100%;
                font-size: em(18);
            }

            &__title {
                margin-bottom: em(5);
                font-size: em(56);
                line-height: 1.25;
            }

            &__flex {
                display: block;
            }

            &__animation-container {
                display: block;
                width: 100%;
            }

            &__animation {
                width: 100%;
                height: 100%;
                position: relative;
                left: -50px;
                top: -100px;
            }
        }
    }

    .form-container {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 em(30);

        &__animate {
            width: 100%;
            animation-duration: 0.75s;
        }

        @include for-desktop-up {
            height: 80%;
            padding: em(110) em(50) 0 em(50);
        }
    }

    .nav-content {
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1000;
        top: em(55);
        right: 0;
        font-size: em(14);
        color: $color-gray-text;

        &__group {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &__btn {
            @include btn-primary;
            font-size: em(14);
            margin-right: em(8);

            &--secondary {
                @include btn-secondary;
                font-size: em(14);
            }
        }

        @include for-desktop-up {
            display: flex;
        }
    }
</style>
